import _definePage_default_0 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/[...all].vue?definePage&vue'
import _definePage_default_3 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/index.vue?definePage&vue'
import _definePage_default_4 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/point24/index.vue?definePage&vue'
import _definePage_default_5 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/snake/index.vue?definePage&vue'
import _definePage_default_6 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/gift/index.vue?definePage&vue'
import _definePage_default_7 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/gift/custom.vue?definePage&vue'
import _definePage_default_8 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/login.vue?definePage&vue'
import _definePage_default_9 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/order/gift.vue?definePage&vue'
import _definePage_default_10 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/profile/index.vue?definePage&vue'
import _definePage_default_11 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/profile/child.vue?definePage&vue'
import _definePage_default_12 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/record/integral.vue?definePage&vue'
import _definePage_default_13 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/index.vue?definePage&vue'
import _definePage_default_14 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/[id].vue?definePage&vue'
import _definePage_default_15 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/custom.vue?definePage&vue'
import _definePage_default_16 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/level/[id]/custom.vue?definePage&vue'
import _definePage_default_17 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/task/index.vue?definePage&vue'
import _definePage_default_18 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/task/custom.vue?definePage&vue'
import _definePage_default_19 from '/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/words/custom.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'home',
    component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:all(.*)',
    name: '404',
    component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/[...all].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/components',
    /* internal name: '/components' */
    /* no component */
    children: [
      {
        path: 'rules',
        /* internal name: '/components/rules' */
        /* no component */
        children: [
          {
            path: 'theLearnEngWordForm',
            name: '/components/rules/theLearnEngWordForm',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/components/rules/theLearnEngWordForm.vue'),
            /* no children */
          },
          {
            path: 'theLearnEngWordItem',
            name: '/components/rules/theLearnEngWordItem',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/components/rules/theLearnEngWordItem.vue'),
            /* no children */
          },
          {
            path: 'thePoint24RulesForm',
            name: '/components/rules/thePoint24RulesForm',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/components/rules/thePoint24RulesForm.vue'),
            /* no children */
          },
          {
            path: 'theRulesForm',
            name: '/components/rules/theRulesForm',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/components/rules/theRulesForm.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/game',
    /* internal name: '/game' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'game',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
      {
        path: 'point24',
        /* internal name: '/game/point24' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'gamePoint24',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/point24/index.vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
      {
        path: 'snake',
        /* internal name: '/game/snake' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'gameSnake',
            component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/game/snake/index.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      }
    ],
  },
  {
    path: '/gift',
    /* internal name: '/gift' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'gift',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/gift/index.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'custom',
        name: 'giftCustom',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/gift/custom.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_8
  ),
  {
    path: '/order',
    /* internal name: '/order' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'gift',
        name: 'orderGift',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/order/gift.vue'),
        /* no children */
      },
  _definePage_default_9
  )
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'profile',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/profile/index.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'child',
        name: 'profile/child',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/profile/child.vue'),
        /* no children */
      },
  _definePage_default_11
  )
    ],
  },
  {
    path: '/record',
    /* internal name: '/record' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'integral',
        name: 'recordIntegral',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/record/integral.vue'),
        /* no children */
      },
  _definePage_default_12
  )
    ],
  },
  {
    path: '/story',
    /* internal name: '/story' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'story',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/index.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'storyDetail',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/[id].vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'custom',
        name: 'storyCustom',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/custom.vue'),
        /* no children */
      },
  _definePage_default_15
  ),
      {
        path: 'level',
        /* internal name: '/story/level' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/story/level/[id]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'custom',
                name: 'storyLevelCustom',
                component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/story/level/[id]/custom.vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/task',
    /* internal name: '/task' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'task',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/task/index.vue'),
        /* no children */
      },
  _definePage_default_17
  ),
  _mergeRouteRecord(
      {
        path: 'custom',
        name: 'taskCustom',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/task/custom.vue'),
        /* no children */
      },
  _definePage_default_18
  )
    ],
  },
  {
    path: '/words',
    /* internal name: '/words' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'custom',
        name: 'words',
        component: () => import('/Users/xiejun/Documents/self/smart-family/sf-h5/sf-h5-app/src/pages/words/custom.vue'),
        /* no children */
      },
  _definePage_default_19
  )
    ],
  }
]
